<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            ref="crudListAssociado"
            v-model="associados"
            :headers="headers"
            :actions="actions"
            :permission="'list_associado'"
            :sort-by="['cnpj']"
            :sort-desc="[false]"
            :slots="[
              'item.status',
              'item.telefones'
            ]"
            :export-pdf-xlsx="true"
          >
            <template
              v-if="hasPermission('inserir_associado')"
              v-slot:btnCadastro
            >
              <v-col>
                <planilha-upload-dialog
                  :template-id="3"
                  block
                  color="primary"
                />
              </v-col>
              <v-col>
                <v-btn
                  class="text-none text-white"
                  block
                  rounded
                  color="primary"
                  title="Cadastrar novo associado"
                  @click="$router.push({ path: 'associados/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template
              v-if="hasPermission('ativar_associado')"
              v-slot:[`item.status`]="{ item }"
            >
              <v-checkbox
                v-model="item.status"
                class="mt-0"
                color="success"
                :readonly="!hasPermission('ativar_associado')"
                :loading="false"
                hint="Ativar/Desativar associado!"
                hide-details
                @change="TOGGLE_ASSOCIADO_STATUS(item)"
              />
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import PlanilhaUploadDialog from '../../components/PlanillhaUpload'
  import AssociadosStore, { BOOTSTRAP, INATIVAR_ASSOCIADO, TOGGLE_ASSOCIADO_STATUS } from '@/store/modules/associados'
  import { mapState, mapActions, mapGetters } from 'vuex'
  export default {
    components: {
      PlanilhaUploadDialog,
    },
    props: {
      cnpjs: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar',
            color: 'info darken-3',
            click: item => this.getAssociado(item),
            icon: 'mdi-pencil',
            permission: 'editar_associado',
          },
          {
            title: 'Excluir',
            color: 'red darken-3',
            click: item => this.INATIVAR_ASSOCIADO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_associado',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, width: '40px' },
          { align: 'center', sortable: false, groupable: false, text: 'Status', value: 'status', width: '40px', floatingfilter: true, report: true, styleReport: { status: true } },
          { align: 'center', sortable: true, groupable: false, text: 'Código', value: 'codigo', width: '10px', floatingfilter: true, report: true, styleReport: { halign: 'right' } },
          { align: 'left', sortable: true, groupable: false, text: 'CPF/CNPJ', value: 'cpf_cnpj', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome/Razão Social', value: 'nome_razao_social', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome Fantasia', value: 'nome_fantasia', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Email', value: 'email', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto', report: true, styleReport: { cellWidth: 30 } },
          { align: 'left', sortable: true, groupable: true, text: 'Papel', value: 'roles.description', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Logradouro', value: 'endereco.logradouro', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Número', value: 'endereco.numero', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Complemento', value: 'endereco.complemento', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Bairro', value: 'endereco.bairro', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: true, text: 'Cidade', value: 'endereco.cidade.nome', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: true, text: 'Estado', value: 'endereco.estado.sigla', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'CEP', value: 'endereco.cep', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('associados', ['loading', 'associados']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.hasModule('associados')) { this.$store.registerModule('associados', AssociadosStore) }
      this.BOOTSTRAP()
    },
    updated () {
      if (this.cnpjs) {
        this.filterCnpj(this.cnpjs)
      }
    },
    methods: {
      ...mapActions('associados', [BOOTSTRAP, INATIVAR_ASSOCIADO, TOGGLE_ASSOCIADO_STATUS]),
      filterCnpj (cnpj) {
        const arrayCnpjs = JSON.parse(cnpj)
        let elemento
        for (let i = 0; i <= arrayCnpjs.length; i++) {
          elemento = this.$refs.crudListAssociado.filters.cpf_cnpj.indexOf(arrayCnpjs[i])
          if (this.$refs.crudListAssociado.filters.cpf_cnpj && elemento === -1) {
            this.$refs.crudListAssociado.filters.cpf_cnpj.push(arrayCnpjs[i])
          } else {
            this.$refs.crudListAssociado.filters.cpf_cnpj.splice(elemento, 1)
          }
        }
      },
      getAssociado ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/associados/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
